<template>
  <div>
    <FurusatoCouponTable
      ref="table"
      :table-name="tableName"
      :setting-columns-one="columns_one"
      :setting-columns-two="columns_two"
      :actions="actions"
      :filters.sync="filters"
      :search="search"
      :setting-apis="apis"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
      @deletedEntry="onDeletedEntry"
    ></FurusatoCouponTable>
  </div>
</template>
<script>
  import FurusatoCouponTable from "../../_common/List/FurusatoCouponTable";

  export default {
    data() {
      return {
        tableName: this.$t('users.user list'),
        apis: {
          list: {
            endpoint: this.$consts.API.ADMIN.FURUSATO_COUPON,
          },
          delete: {
            endpoint: this.$consts.API.ADMIN.FURUSATO_COUPON_DELETE,
          }
        },
        actions: {
          name: "furusato_coupon",
          exportEntries: true,
          editEntry: true,
          deleteEntry: true,
          routerLink: "furusato-coupon/",
        },
        search: {
          searchAll: false,
          reset: true,
        },
        filters: {
          code_multi_like: {
            name: 'code',
            type: 'multi_like',
            label: this.$t('furusato_coupon.code_coupon'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            options: [],
            boundaryClass: 'col-md-3 col-sm-12'
          },
          full_city_id_equal: {
            name: 'full_city_id',
            type: 'multi_like',
            label: this.$t('furusato_product.furusato_city_id'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          name_multi_like: {
            name: 'name',
            type: 'multi_like',
            label: this.$t('furusato_coupon.coupon_name'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          status_equal: {
            name: 'status',
            type: 'equal',
            label: this.$t('common.status'),
            uiType: 'select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: [
              {
                id: "0",
                name: "未選択"
              },
              {
                id: "1",
                name: "公開",
              },
              {
                id: "2",
                name: "非公開",
              },
            ]
          },
          publish_at_equal: {
            name: 'publish_at',
            type: 'equal',
            label: this.$t('furusato_coupon.reception_date'),
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
          input_hidden: {
            name: 'hidden',
            type: 'equal',
            label: " ",
            uiType: 'input_hidden',
            boundaryClass: 'space-date',
          },
          expire_at_equal: {
            name: 'expire_at',
            type: 'equal',
            label: " ",
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
          created_at_equal: {
            name: 'created_at',
            type: 'equal',
            label: this.$t('common.created at'),
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
          input_hidden_2: {
            name: 'hidden',
            type: 'equal',
            label: " ",
            uiType: 'input_hidden',
            boundaryClass: 'space-date',
          },
          created_end_equal: {
            name: 'created_end',
            type: 'equal',
            label: " ",
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
        },
        entrySelectedDelete: {},
        entryToSave: {active: 1},
        entryToEdit: {},
        columns_one: [
          {name: 'full_city_id', label: this.$t('furusato_product.furusato_city_id'),
            computedHtml: furusato_coupon => {
              return furusato_coupon.cities.name;
            }},
          {name: 'name', label: this.$t('furusato_coupon.coupon_name'), class: 'text-center'},
          {name: 'price', label: this.$t('furusato_coupon.price_discount'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon => {
              return furusato_coupon.price != null ? furusato_coupon.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '円' : "";
            }},
          {name: 'expire_at', label: this.$t('furusato_coupon.reception_date'), class: 'min-w-100',
            computedHtml: furusato_coupon => {
              return moment(furusato_coupon.publish_at).format('YYYY年MM月DD日 HH:mm') + '~ </br>' + moment(furusato_coupon.expire_at).format('YYYY年MM月DD日 HH:mm');
            }},
          {name: 'status', label: this.$t('common.status'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon => {
              let status = '';
              let furusatoCouponStatus = furusato_coupon.status;
              if (furusatoCouponStatus == this.$consts.INPUT.TYPE_PUBLIC) {
                status = this.$t('furusato_coupon.public');
              } else if (furusatoCouponStatus == this.$consts.INPUT.TYPE_DRAFT) {
                status = this.$t('furusato_coupon.non_public');
              }
              return status;
            }},
        ],
        columns_two: [
          {name: 'publish_num', label: this.$t('furusato_coupon.publish_num'), class: 'min-w-100 text-center'},
          {name: 'coupon_owner', label: this.$t('furusato_coupon.status_use_coupon'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon => {
              return furusato_coupon.coupon_owner > 0 ? furusato_coupon.coupon_owner + "人" : "0人";
            }},
        ]
      }
    },
    components: {
      FurusatoCouponTable,
    },
    mounted() {
      this.$request.get(this.$consts.API.ADMIN.UI_HELPER_FURUSATO_COUPON).then(res => {
        if (!res.hasErrors()) {
          this.filters.code_multi_like.options = _.cloneDeep(res.data.code);
          this.filters.full_city_id_equal.options = _.cloneDeep(res.data.full_city);
          this.filters.name_multi_like.options = _.cloneDeep(res.data.name);
        }
      })
    },
    methods: {
      getFurusatoCoupon() {
        this.$request.get(this.$consts.API.ADMIN.FURUSATO_COUPON).then(res => {
          if(!res.hasErrors()) {
            this.filters = _.cloneDeep(this.filters)
          }
        })
      },
      onResetAllSearch() {
        this.getFurusatoCoupon();
      },
      onSearch() {
        this.getFurusatoCoupon();
      },
      onDeletedEntry() {
        this.getFurusatoCoupon();
      },
    }
  }
</script>
